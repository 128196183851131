import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import Preloader from "../components/Preloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [suspendModalOpen, setSuspendModalOpen] = useState(false);
  const [unsuspendModalOpen, setUnsuspendModalOpen] = useState(false);
  const [disableTransferModalOpen, setDisableTransferModalOpen] =
    useState(false);
  const [enableTransferModalOpen, setEnableTransferModalOpen] = useState(false);
  const [idToSuspend, setIdtoSuspend] = useState("");
  const [idToUnsuspend, setIdtoUnsuspend] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [toolsActive, setToolsActive] = useState(false);
  const [displayedUsers, setDisplayedUsers] = useState(10);
  const [usersToDisplay, setUsersToDisplay] = useState(displayedUsers);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchingForUser, setSearchingForUser] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [idToVerify, setIdToVerify] = useState("");
  const [idToEnableTransfer, setIdToEnableTransfer] = useState("");
  const [idToDisableTransfer, setIdToDisableTransfer] = useState("");
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [addTransactionModalOpen, setAddTransactionModalOpen] = useState(false);
  const [transactionType, setTransactionType] = useState("Deposit");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("Completed");
  const [description, setDescription] = useState("");
  const [transferOption, setTransferOption] = useState("");
  const [dateTime, setDateTime] = useState<any>(new Date());
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bankName, setBankName] = useState("");
  const [country, setCountry] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [editBalanceModalOpen, setEditBalanceModalOpen] = useState(false);
  const [idToEdit, setIdToEdit] = useState("");
  const [amountToEdit, setAmountToEdit] = useState("");
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [registerFormData, setRegisterFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
  });

  const navigate = useNavigate();

  function getRandom(length: number) {
    return Math.floor(
      Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
    );
  }

  const inputChange = (e: any) => {
    setRegisterFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const loadMoreUsers = () => {
    setUsersToDisplay(usersToDisplay + displayedUsers);
  };

  const toggleSearchActive = () => {
    setSearchActive(!searchActive);
  };

  const toggleToolsActive = () => {
    setToolsActive(!toolsActive);
  };

  const suspendUser = async () => {
    const token = localStorage.getItem("firstwebster-admin");
    setLoading(true);

    try {
      await axios.put(
        "https://api.firstwebster.com/api/user/suspend",
        { idToSuspend },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setSuspendModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const enableTransfer = async () => {
    const token = localStorage.getItem("firstwebster-admin");
    setLoading(true);

    try {
      await axios.put(
        "https://api.firstwebster.com/api/user/enable-transfer",
        { idToEnableTransfer },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setEnableTransferModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const unsuspendUser = async () => {
    const token = localStorage.getItem("firstwebster-admin");
    setLoading(true);

    try {
      await axios.put(
        "https://api.firstwebster.com/api/user/unsuspend",
        { idToUnsuspend },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setUnsuspendModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const disableTransfer = async () => {
    const token = localStorage.getItem("firstwebster-admin");
    setLoading(true);

    try {
      await axios.put(
        "https://api.firstwebster.com/api/user/disable-transfer",
        { idToDisableTransfer },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setDisableTransferModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const verifyEmail = async () => {
    const token = localStorage.getItem("firstwebster-admin");
    setLoading(true);

    try {
      await axios.put(
        `https://api.firstwebster.com/api/user/verify/${idToVerify}`,
        { verified: true },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const addTransaction = async () => {
    const token = localStorage.getItem("firstwebster-admin");
    setLoading(true);

    try {
      await axios.post(
        "https://api.firstwebster.com/api/transaction/add",
        {
          user: selectedUserId,
          type: transactionType,
          amount: +amount,
          transactionId: dateTime.getTime().toString(),
          status,
          description,
          transferOption,
          dateTime,
          bankDetails: {
            accountName,
            accountType,
            accountNumber,
            bankName,
            routingNumber,
            country,
            swiftCode,
          },
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction added!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const editBalance = async () => {
    const token = localStorage.getItem("firstwebster-admin");
    setLoading(true);

    try {
      await axios.put(
        `https://api.firstwebster.com/api/user/balance/${idToEdit}`,
        {
          balance: amountToEdit,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Balance updated!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const createAccount = async () => {
    if (registerFormData.password.length < 8) {
      toast.error("Weak password");
      return;
    }

    if (registerFormData.password !== registerFormData.confirmPassword) {
      setRegisterFormData((prevState) => ({
        ...prevState,
        password: "",
        confirmPassword: "",
      }));
      toast.error("Passwords do not match");
      return;
    }

    try {
      setLoading(true);

      await axios.post("https://api.firstwebster.com/api/auth/admin-register", {
        firstName: registerFormData.firstName,
        middleName: registerFormData.middleName,
        lastName: registerFormData.lastName,
        email: registerFormData.email?.toLowerCase(),
        password: registerFormData.password,
        phone: registerFormData.phone,
        accountNumber: `9${getRandom(11)}`,
      });

      setLoading(false);
      toast.success("Account created!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("firstwebster-admin");

    const getUsers = async () => {
      const { data } = await axios.get(
        "https://api.firstwebster.com/api/user/find",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setUsers(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getUsers();
  }, []);

  useEffect(() => {
    if (searchingForUser) {
      const foundUser = users?.filter((user: any) => {
        return Object.values(user.firstName)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(foundUser);

      if (foundUser.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  }, [searchingForUser, searchTerm, users]);

  return (
    <>
      {pageLoading ? (
        <Preloader />
      ) : (
        <div className="nk-body bg-lighter npc-general has-sidebar ui-bordered ">
          <div className="nk-app-root">
            <div className="nk-main ">
              <Sidebar />
              <div className="nk-wrap ">
                <Header />
                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                          <div className="nk-block-between">
                            <div className="nk-block-head-content">
                              <h3 className="nk-block-title page-title">
                                Accounts
                              </h3>
                              <div className="nk-block-des text-soft">
                                <p>You have total {users?.length} accounts</p>
                              </div>
                            </div>
                            <div className="nk-block-head-content">
                              <a
                                href="#/"
                                onClick={() => setCreateAccountModalOpen(true)}
                                className="btn btn-primary bg-primary d-none d-sm-inline-flex"
                              >
                                <em className="icon ni ni-plus" />
                                <span>Create account</span>
                              </a>
                              <a
                                href="#/"
                                onClick={() => setCreateAccountModalOpen(true)}
                                className="btn btn-primary bg-primary d-inline-flex d-sm-none"
                              >
                                <em className="icon ni ni-plus" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="card card-stretch">
                            <div className="card-inner-group">
                              <div className="card-inner position-relative card-tools-tggle">
                                <div className="card-title-group">
                                  <div className="card-tools"></div>
                                  <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={toggleSearchActive}
                                          className="btn btn-icon search-toggle toggle-search"
                                          data-target="search"
                                        >
                                          <em className="icon ni ni-search" />
                                        </a>
                                      </li>
                                      <li className="btn-toolbar-sep" />
                                      <li>
                                        <div className="toggle-wrap">
                                          <a
                                            href="#/"
                                            onClick={toggleToolsActive}
                                            className="btn btn-icon btn-trigger toggle"
                                            data-target="cardTools"
                                          >
                                            <em className="icon ni ni-menu-right" />
                                          </a>
                                          <div
                                            className={`toggle-content ${
                                              toolsActive
                                                ? "content-active"
                                                : ""
                                            }`}
                                            data-content="cardTools"
                                          >
                                            <ul className="btn-toolbar gx-1">
                                              <li className="toggle-close">
                                                <a
                                                  href="#/"
                                                  onClick={toggleToolsActive}
                                                  className="btn btn-icon btn-trigger toggle"
                                                  data-target="cardTools"
                                                >
                                                  <em className="icon ni ni-arrow-left" />
                                                </a>
                                              </li>

                                              <li>
                                                <div className="dropdown">
                                                  <a
                                                    href="#/"
                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-setting" />
                                                  </a>
                                                  <div className="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                                                    <ul className="link-check">
                                                      <li>
                                                        <span>Show</span>
                                                      </li>
                                                      <li
                                                        onClick={() =>
                                                          setDisplayedUsers(10)
                                                        }
                                                        className={
                                                          displayedUsers === 10
                                                            ? "active"
                                                            : ""
                                                        }
                                                      >
                                                        <a href="#/">10</a>
                                                      </li>
                                                      <li
                                                        onClick={() =>
                                                          setDisplayedUsers(20)
                                                        }
                                                        className={
                                                          displayedUsers === 20
                                                            ? "active"
                                                            : ""
                                                        }
                                                      >
                                                        <a href="#/">20</a>
                                                      </li>
                                                      <li
                                                        onClick={() =>
                                                          setDisplayedUsers(50)
                                                        }
                                                        className={
                                                          displayedUsers === 50
                                                            ? "active"
                                                            : ""
                                                        }
                                                      >
                                                        <a href="#/">50</a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className={`card-search search-wrap ${
                                    searchActive ? "active" : ""
                                  }`}
                                  data-search="search"
                                >
                                  <div className="card-body">
                                    <div className="search-content">
                                      <a
                                        href="#/"
                                        onClick={toggleSearchActive}
                                        className="search-back btn btn-icon toggle-search"
                                        data-target="search"
                                      >
                                        <em className="icon ni ni-arrow-left" />
                                      </a>
                                      <input
                                        type="text"
                                        className="form-control border-transparent form-focus-none"
                                        placeholder="Search by user first name"
                                        onChange={(e) => {
                                          setSearchingForUser(true);
                                          setSearchTerm(e.target.value);
                                        }}
                                      />
                                      <button className="search-submit btn btn-icon">
                                        <em className="icon ni ni-search" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-inner p-0">
                                <div className="nk-tb-list nk-tb-ulist is-compact">
                                  <div className="nk-tb-item nk-tb-head">
                                    <div className="nk-tb-col">
                                      <span className="sub-text">Name</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-md">
                                      <span className="sub-text">Email</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-sm">
                                      <span className="sub-text">Phone</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-md">
                                      <span className="sub-text">Balance</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-lg">
                                      <span className="sub-text">
                                        Joined on
                                      </span>
                                    </div>
                                    <div className="nk-tb-col">
                                      <span className="sub-text">Status</span>
                                    </div>
                                    <div className="nk-tb-col nk-tb-col-tools text-end"></div>
                                  </div>
                                  {!searchTerm && users?.length > 0 && (
                                    <>
                                      {users
                                        ?.slice(0, usersToDisplay)
                                        ?.map((user: any) => (
                                          <div
                                            className="nk-tb-item"
                                            key={user._id}
                                          >
                                            <div className="nk-tb-col">
                                              <div className="user-card">
                                                <div className="user-name">
                                                  <span className="tb-lead">
                                                    {user.firstName}{" "}
                                                    {user.middleName}{" "}
                                                    {user.lastName}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="nk-tb-col tb-col-md">
                                              <span>{user.email}</span>
                                            </div>
                                            <div className="nk-tb-col tb-col-sm">
                                              <span>{user.phone}</span>
                                            </div>
                                            <div className="nk-tb-col tb-col-md">
                                              <span>
                                                $
                                                {user.balance?.toLocaleString()}
                                              </span>
                                            </div>
                                            <div className="nk-tb-col tb-col-lg">
                                              <span>
                                                {new Date(
                                                  user.createdAt
                                                ).toDateString()}
                                              </span>
                                            </div>
                                            <div className="nk-tb-col">
                                              {user.disabled ? (
                                                <span className="tb-status text-danger">
                                                  Suspended
                                                </span>
                                              ) : (
                                                <span className="tb-status text-success">
                                                  Active
                                                </span>
                                              )}
                                            </div>
                                            <div className="nk-tb-col nk-tb-col-tools">
                                              <ul className="nk-tb-actions gx-2">
                                                <li>
                                                  <div className="drodown">
                                                    <a
                                                      href="#/"
                                                      className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
                                                      data-bs-toggle="dropdown"
                                                    >
                                                      <em className="icon ni ni-more-h" />
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                      <ul className="link-list-opt no-bdr">
                                                        <li>
                                                          <Link
                                                            to={`/user/${user._id}`}
                                                          >
                                                            <em className="icon ni ni-eye" />
                                                            <span>
                                                              View Details
                                                            </span>
                                                          </Link>
                                                        </li>
                                                        <li className="divider" />
                                                        <li>
                                                          <a
                                                            href="#/"
                                                            onClick={() => {
                                                              setSelectedUserId(
                                                                user._id
                                                              );
                                                              setAddTransactionModalOpen(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <span>
                                                              Add Transaction
                                                            </span>
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="#/"
                                                            onClick={() => {
                                                              setIdToEdit(
                                                                user._id
                                                              );
                                                              setEditBalanceModalOpen(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            <span>
                                                              Edit Balance
                                                            </span>
                                                          </a>
                                                        </li>
                                                        {!user.verified && (
                                                          <>
                                                            <li>
                                                              <a
                                                                href="#/"
                                                                onClick={() => {
                                                                  setIdToVerify(
                                                                    user._id
                                                                  );
                                                                  setVerifyModalOpen(
                                                                    true
                                                                  );
                                                                }}
                                                              >
                                                                <em className="icon ni ni-shield-star" />
                                                                <span>
                                                                  Verify Email
                                                                </span>
                                                              </a>
                                                            </li>
                                                            <li className="divider" />
                                                          </>
                                                        )}
                                                        {!user.disabled ? (
                                                          <li>
                                                            <a
                                                              href="#/"
                                                              onClick={() => {
                                                                setSuspendModalOpen(
                                                                  true
                                                                );
                                                                setIdtoSuspend(
                                                                  user._id
                                                                );
                                                              }}
                                                            >
                                                              <span>
                                                                Suspend Account
                                                              </span>
                                                            </a>
                                                          </li>
                                                        ) : (
                                                          <li>
                                                            <a
                                                              href="#/"
                                                              onClick={() => {
                                                                setUnsuspendModalOpen(
                                                                  true
                                                                );
                                                                setIdtoUnsuspend(
                                                                  user._id
                                                                );
                                                              }}
                                                            >
                                                              <span>
                                                                Unsuspend
                                                                Account
                                                              </span>
                                                            </a>
                                                          </li>
                                                        )}
                                                        {!user.transferDisabled ? (
                                                          <li>
                                                            <a
                                                              href="#/"
                                                              onClick={() => {
                                                                setDisableTransferModalOpen(
                                                                  true
                                                                );
                                                                setIdToDisableTransfer(
                                                                  user._id
                                                                );
                                                              }}
                                                            >
                                                              <span>
                                                                Disable Transfer
                                                              </span>
                                                            </a>
                                                          </li>
                                                        ) : (
                                                          <li>
                                                            <a
                                                              href="#/"
                                                              onClick={() => {
                                                                setEnableTransferModalOpen(
                                                                  true
                                                                );
                                                                setIdToEnableTransfer(
                                                                  user._id
                                                                );
                                                              }}
                                                            >
                                                              <span>
                                                                Enable Transfer
                                                              </span>
                                                            </a>
                                                          </li>
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        ))}
                                    </>
                                  )}
                                  {searchTerm && searchResults?.length > 0 && (
                                    <>
                                      {searchResults?.map((user: any) => (
                                        <div
                                          className="nk-tb-item"
                                          key={user._id}
                                        >
                                          <div className="nk-tb-col">
                                            <div className="user-card">
                                              <div className="user-name">
                                                <span className="tb-lead">
                                                  {user.firstName}{" "}
                                                  {user.middleName}{" "}
                                                  {user.lastName}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="nk-tb-col tb-col-md">
                                            <span>{user.email}</span>
                                          </div>
                                          <div className="nk-tb-col tb-col-sm">
                                            <span>{user.phone}</span>
                                          </div>
                                          <div className="nk-tb-col tb-col-md">
                                            ${user.balance?.toLocaleString()}
                                          </div>
                                          <div className="nk-tb-col tb-col-lg">
                                            <span>
                                              {new Date(
                                                user.createdAt
                                              ).toDateString()}
                                            </span>
                                          </div>
                                          <div className="nk-tb-col">
                                            {user.disabled ? (
                                              <span className="tb-status text-danger">
                                                Suspended
                                              </span>
                                            ) : (
                                              <span className="tb-status text-success">
                                                Active
                                              </span>
                                            )}
                                          </div>
                                          <div className="nk-tb-col nk-tb-col-tools">
                                            <ul className="nk-tb-actions gx-2">
                                              <li>
                                                <div className="drodown">
                                                  <a
                                                    href="#/"
                                                    className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                  >
                                                    <em className="icon ni ni-more-h" />
                                                  </a>
                                                  <div className="dropdown-menu dropdown-menu-end">
                                                    <ul className="link-list-opt no-bdr">
                                                      <li>
                                                        <Link
                                                          to={`/user/${user._id}`}
                                                        >
                                                          <em className="icon ni ni-eye" />
                                                          <span>
                                                            View Details
                                                          </span>
                                                        </Link>
                                                      </li>
                                                      <li className="divider" />
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setSelectedUserId(
                                                              user._id
                                                            );
                                                            setAddTransactionModalOpen(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <span>
                                                            Add Transaction
                                                          </span>
                                                        </a>
                                                      </li>
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setIdToEdit(
                                                              user._id
                                                            );
                                                            setEditBalanceModalOpen(
                                                              true
                                                            );
                                                          }}
                                                        >
                                                          <span>
                                                            Edit Balance
                                                          </span>
                                                        </a>
                                                      </li>
                                                      {!user.verified && (
                                                        <>
                                                          <li>
                                                            <a
                                                              href="#/"
                                                              onClick={() => {
                                                                setIdToVerify(
                                                                  user._id
                                                                );
                                                                setVerifyModalOpen(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              <em className="icon ni ni-shield-star" />
                                                              <span>
                                                                Verify Email
                                                              </span>
                                                            </a>
                                                          </li>
                                                          <li className="divider" />
                                                        </>
                                                      )}
                                                      {!user.disabled ? (
                                                        <li>
                                                          <a
                                                            href="#/"
                                                            onClick={() => {
                                                              setSuspendModalOpen(
                                                                true
                                                              );
                                                              setIdtoSuspend(
                                                                user._id
                                                              );
                                                            }}
                                                          >
                                                            <span>
                                                              Suspend Account
                                                            </span>
                                                          </a>
                                                        </li>
                                                      ) : (
                                                        <li>
                                                          <a
                                                            href="#/"
                                                            onClick={() => {
                                                              setUnsuspendModalOpen(
                                                                true
                                                              );
                                                              setIdtoUnsuspend(
                                                                user._id
                                                              );
                                                            }}
                                                          >
                                                            <span>
                                                              Unsuspend Account
                                                            </span>
                                                          </a>
                                                        </li>
                                                      )}
                                                      {!user.transferDisabled ? (
                                                        <li>
                                                          <a
                                                            href="#/"
                                                            onClick={() => {
                                                              setDisableTransferModalOpen(
                                                                true
                                                              );
                                                              setIdToDisableTransfer(
                                                                user._id
                                                              );
                                                            }}
                                                          >
                                                            <span>
                                                              Disable Transfer
                                                            </span>
                                                          </a>
                                                        </li>
                                                      ) : (
                                                        <li>
                                                          <a
                                                            href="#/"
                                                            onClick={() => {
                                                              setEnableTransferModalOpen(
                                                                true
                                                              );
                                                              setIdToEnableTransfer(
                                                                user._id
                                                              );
                                                            }}
                                                          >
                                                            <span>
                                                              Enable Transfer
                                                            </span>
                                                          </a>
                                                        </li>
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                  {(noResults || users?.length === 0) && (
                                    <p
                                      style={{
                                        position: "relative",
                                        left: "50%",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      No records
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {addTransactionModalOpen && (
                          <>
                            <div
                              className="nk-add-product toggle-slide toggle-slide-right toggle-screen-any content-active"
                              data-content="addProduct"
                              data-toggle-screen="any"
                              data-toggle-overlay="true"
                              data-toggle-body="true"
                              data-simplebar
                            >
                              <div className="nk-block-head">
                                <div className="nk-block-head-content">
                                  <h5 className="nk-block-title">
                                    Add Transaction
                                  </h5>
                                </div>
                              </div>
                              <div className="nk-block">
                                <div className="row g-3">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="type"
                                      >
                                        Transaction Type
                                      </label>
                                      <div className="form-control-wrap">
                                        <select
                                          className="form-control"
                                          id="type"
                                          value={transactionType}
                                          onChange={(e) => {
                                            setTransactionType(e.target.value);
                                          }}
                                        >
                                          <option value="Deposit">
                                            Deposit
                                          </option>
                                          <option value="Withdrawal">
                                            Withdrawal
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="amount"
                                      >
                                        Amount ($)
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="amount"
                                          required
                                          value={amount}
                                          onChange={(e) =>
                                            setAmount(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="status"
                                      >
                                        Status
                                      </label>
                                      <div className="form-control-wrap">
                                        <select
                                          className="form-control"
                                          id="status"
                                          value={status}
                                          onChange={(e) =>
                                            setStatus(e.target.value)
                                          }
                                        >
                                          <option value="Completed">
                                            Completed
                                          </option>
                                          <option value="Pending">
                                            Pending
                                          </option>
                                          <option value="Unconfirmed">
                                            Unconfirmed
                                          </option>
                                          <option value="Cancelled">
                                            Cancelled
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="date-time"
                                      >
                                        Date/Time
                                      </label>
                                      <div className="form-control-wrap">
                                        <DateTimePicker
                                          onChange={(value) =>
                                            setDateTime(value)
                                          }
                                          value={dateTime}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {transactionType === "Withdrawal" && (
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label
                                          className="form-label"
                                          htmlFor="transfer-option"
                                        >
                                          Transfer Option
                                        </label>
                                        <div className="form-control-wrap">
                                          <select
                                            id="transfer-option"
                                            className="form-control"
                                            value={transferOption}
                                            onChange={(e) =>
                                              setTransferOption(e.target.value)
                                            }
                                          >
                                            <option value="">
                                              Choose transfer option
                                            </option>
                                            <option value="Local">Local</option>
                                            <option value="International">
                                              International
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {transactionType === "Withdrawal" &&
                                    transferOption === "Local" && (
                                      <>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="account-name"
                                            >
                                              Account Name
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="account-name"
                                                value={accountName}
                                                onChange={(e) => {
                                                  setAccountName(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="account-number"
                                            >
                                              Account Number
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="number"
                                                className="form-control"
                                                id="account-number"
                                                value={accountNumber}
                                                onChange={(e) => {
                                                  setAccountNumber(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="bank-name"
                                            >
                                              Bank Name
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="bank-name"
                                                value={bankName}
                                                onChange={(e) => {
                                                  setBankName(e.target.value);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="account-type"
                                            >
                                              Account Type
                                            </label>
                                            <div className="form-control-wrap">
                                              <select
                                                id="account-type"
                                                className="form-control"
                                                value={accountType}
                                                onChange={(e) =>
                                                  setAccountType(e.target.value)
                                                }
                                              >
                                                <option value="">--</option>
                                                <option value="Savings account">
                                                  Savings account
                                                </option>
                                                <option value="Current account">
                                                  Current account
                                                </option>
                                                <option value="Checking account">
                                                  Checking account
                                                </option>
                                                <option value="Fixed Deposit">
                                                  Fixed Deposit
                                                </option>
                                                <option value="Non-resident account">
                                                  Non-resident account
                                                </option>
                                                <option value="Online banking">
                                                  Online banking
                                                </option>
                                                <option value="Domiciliary account">
                                                  Domiciliary account
                                                </option>
                                                <option value="Joint account">
                                                  Joint account
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  {transactionType === "Withdrawal" &&
                                    transferOption === "International" && (
                                      <>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="account-name"
                                            >
                                              Account Name
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="account-name"
                                                value={accountName}
                                                onChange={(e) => {
                                                  setAccountName(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="account-number"
                                            >
                                              Account Number
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="number"
                                                className="form-control"
                                                id="account-number"
                                                value={accountNumber}
                                                onChange={(e) => {
                                                  setAccountNumber(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="bank-name"
                                            >
                                              Bank Name
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="bank-name"
                                                value={bankName}
                                                onChange={(e) => {
                                                  setBankName(e.target.value);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="account-type"
                                            >
                                              Account Type
                                            </label>
                                            <div className="form-control-wrap">
                                              <select
                                                id="account-type"
                                                className="form-control"
                                                value={accountType}
                                                onChange={(e) =>
                                                  setAccountType(e.target.value)
                                                }
                                              >
                                                <option value="">--</option>
                                                <option value="Savings account">
                                                  Savings account
                                                </option>
                                                <option value="Current account">
                                                  Current account
                                                </option>
                                                <option value="Checking account">
                                                  Checking account
                                                </option>
                                                <option value="Fixed Deposit">
                                                  Fixed Deposit
                                                </option>
                                                <option value="Non-resident account">
                                                  Non-resident account
                                                </option>
                                                <option value="Online banking">
                                                  Online banking
                                                </option>
                                                <option value="Domiciliary account">
                                                  Domiciliary account
                                                </option>
                                                <option value="Joint account">
                                                  Joint account
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="routing-number"
                                            >
                                              Routing Number
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="number"
                                                className="form-control"
                                                id="routing-number"
                                                value={routingNumber}
                                                onChange={(e) => {
                                                  setRoutingNumber(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="country"
                                            >
                                              Country
                                            </label>
                                            <div className="form-control-wrap">
                                              <select
                                                id="country"
                                                className="form-control"
                                                value={country}
                                                onChange={(e) =>
                                                  setCountry(e.target.value)
                                                }
                                              >
                                                <option value="">-</option>
                                                <option value="Afganistan">
                                                  Afghanistan
                                                </option>
                                                <option value="Albania">
                                                  Albania
                                                </option>
                                                <option value="Algeria">
                                                  Algeria
                                                </option>
                                                <option value="American Samoa">
                                                  American Samoa
                                                </option>
                                                <option value="Andorra">
                                                  Andorra
                                                </option>
                                                <option value="Angola">
                                                  Angola
                                                </option>
                                                <option value="Anguilla">
                                                  Anguilla
                                                </option>
                                                <option value="Antigua &amp; Barbuda">
                                                  Antigua &amp; Barbuda
                                                </option>
                                                <option value="Argentina">
                                                  Argentina
                                                </option>
                                                <option value="Armenia">
                                                  Armenia
                                                </option>
                                                <option value="Aruba">
                                                  Aruba
                                                </option>
                                                <option value="Australia">
                                                  Australia
                                                </option>
                                                <option value="Austria">
                                                  Austria
                                                </option>
                                                <option value="Azerbaijan">
                                                  Azerbaijan
                                                </option>
                                                <option value="Bahamas">
                                                  Bahamas
                                                </option>
                                                <option value="Bahrain">
                                                  Bahrain
                                                </option>
                                                <option value="Bangladesh">
                                                  Bangladesh
                                                </option>
                                                <option value="Barbados">
                                                  Barbados
                                                </option>
                                                <option value="Belarus">
                                                  Belarus
                                                </option>
                                                <option value="Belgium">
                                                  Belgium
                                                </option>
                                                <option value="Belize">
                                                  Belize
                                                </option>
                                                <option value="Benin">
                                                  Benin
                                                </option>
                                                <option value="Bermuda">
                                                  Bermuda
                                                </option>
                                                <option value="Bhutan">
                                                  Bhutan
                                                </option>
                                                <option value="Bolivia">
                                                  Bolivia
                                                </option>
                                                <option value="Bonaire">
                                                  Bonaire
                                                </option>
                                                <option value="Bosnia &amp; Herzegovina">
                                                  Bosnia &amp; Herzegovina
                                                </option>
                                                <option value="Botswana">
                                                  Botswana
                                                </option>
                                                <option value="Brazil">
                                                  Brazil
                                                </option>
                                                <option value="British Indian Ocean Ter">
                                                  British Indian Ocean Ter
                                                </option>
                                                <option value="Brunei">
                                                  Brunei
                                                </option>
                                                <option value="Bulgaria">
                                                  Bulgaria
                                                </option>
                                                <option value="Burkina Faso">
                                                  Burkina Faso
                                                </option>
                                                <option value="Burundi">
                                                  Burundi
                                                </option>
                                                <option value="Cambodia">
                                                  Cambodia
                                                </option>
                                                <option value="Cameroon">
                                                  Cameroon
                                                </option>
                                                <option value="Canada">
                                                  Canada
                                                </option>
                                                <option value="Canary Islands">
                                                  Canary Islands
                                                </option>
                                                <option value="Cape Verde">
                                                  Cape Verde
                                                </option>
                                                <option value="Cayman Islands">
                                                  Cayman Islands
                                                </option>
                                                <option value="Central African Republic">
                                                  Central African Republic
                                                </option>
                                                <option value="Chad">
                                                  Chad
                                                </option>
                                                <option value="Channel Islands">
                                                  Channel Islands
                                                </option>
                                                <option value="Chile">
                                                  Chile
                                                </option>
                                                <option value="China">
                                                  China
                                                </option>
                                                <option value="Christmas Island">
                                                  Christmas Island
                                                </option>
                                                <option value="Cocos Island">
                                                  Cocos Island
                                                </option>
                                                <option value="Colombia">
                                                  Colombia
                                                </option>
                                                <option value="Comoros">
                                                  Comoros
                                                </option>
                                                <option value="Congo">
                                                  Congo
                                                </option>
                                                <option value="Cook Islands">
                                                  Cook Islands
                                                </option>
                                                <option value="Costa Rica">
                                                  Costa Rica
                                                </option>
                                                <option value="Cote DIvoire">
                                                  Cote DIvoire
                                                </option>
                                                <option value="Croatia">
                                                  Croatia
                                                </option>
                                                <option value="Cuba">
                                                  Cuba
                                                </option>
                                                <option value="Curaco">
                                                  Curacao
                                                </option>
                                                <option value="Cyprus">
                                                  Cyprus
                                                </option>
                                                <option value="Czech Republic">
                                                  Czech Republic
                                                </option>
                                                <option value="Denmark">
                                                  Denmark
                                                </option>
                                                <option value="Djibouti">
                                                  Djibouti
                                                </option>
                                                <option value="Dominica">
                                                  Dominica
                                                </option>
                                                <option value="Dominican Republic">
                                                  Dominican Republic
                                                </option>
                                                <option value="East Timor">
                                                  East Timor
                                                </option>
                                                <option value="Ecuador">
                                                  Ecuador
                                                </option>
                                                <option value="Egypt">
                                                  Egypt
                                                </option>
                                                <option value="El Salvador">
                                                  El Salvador
                                                </option>
                                                <option value="Equatorial Guinea">
                                                  Equatorial Guinea
                                                </option>
                                                <option value="Eritrea">
                                                  Eritrea
                                                </option>
                                                <option value="Estonia">
                                                  Estonia
                                                </option>
                                                <option value="Ethiopia">
                                                  Ethiopia
                                                </option>
                                                <option value="Falkland Islands">
                                                  Falkland Islands
                                                </option>
                                                <option value="Faroe Islands">
                                                  Faroe Islands
                                                </option>
                                                <option value="Fiji">
                                                  Fiji
                                                </option>
                                                <option value="Finland">
                                                  Finland
                                                </option>
                                                <option value="France">
                                                  France
                                                </option>
                                                <option value="French Guiana">
                                                  French Guiana
                                                </option>
                                                <option value="French Polynesia">
                                                  French Polynesia
                                                </option>
                                                <option value="French Southern Ter">
                                                  French Southern Ter
                                                </option>
                                                <option value="Gabon">
                                                  Gabon
                                                </option>
                                                <option value="Gambia">
                                                  Gambia
                                                </option>
                                                <option value="Georgia">
                                                  Georgia
                                                </option>
                                                <option value="Germany">
                                                  Germany
                                                </option>
                                                <option value="Ghana">
                                                  Ghana
                                                </option>
                                                <option value="Gibraltar">
                                                  Gibraltar
                                                </option>
                                                <option value="Great Britain">
                                                  Great Britain
                                                </option>
                                                <option value="Greece">
                                                  Greece
                                                </option>
                                                <option value="Greenland">
                                                  Greenland
                                                </option>
                                                <option value="Grenada">
                                                  Grenada
                                                </option>
                                                <option value="Guadeloupe">
                                                  Guadeloupe
                                                </option>
                                                <option value="Guam">
                                                  Guam
                                                </option>
                                                <option value="Guatemala">
                                                  Guatemala
                                                </option>
                                                <option value="Guinea">
                                                  Guinea
                                                </option>
                                                <option value="Guyana">
                                                  Guyana
                                                </option>
                                                <option value="Haiti">
                                                  Haiti
                                                </option>
                                                <option value="Hawaii">
                                                  Hawaii
                                                </option>
                                                <option value="Honduras">
                                                  Honduras
                                                </option>
                                                <option value="Hong Kong">
                                                  Hong Kong
                                                </option>
                                                <option value="Hungary">
                                                  Hungary
                                                </option>
                                                <option value="Iceland">
                                                  Iceland
                                                </option>
                                                <option value="Indonesia">
                                                  Indonesia
                                                </option>
                                                <option value="India">
                                                  India
                                                </option>
                                                <option value="Iran">
                                                  Iran
                                                </option>
                                                <option value="Iraq">
                                                  Iraq
                                                </option>
                                                <option value="Ireland">
                                                  Ireland
                                                </option>
                                                <option value="Isle of Man">
                                                  Isle of Man
                                                </option>
                                                <option value="Israel">
                                                  Israel
                                                </option>
                                                <option value="Italy">
                                                  Italy
                                                </option>
                                                <option value="Jamaica">
                                                  Jamaica
                                                </option>
                                                <option value="Japan">
                                                  Japan
                                                </option>
                                                <option value="Jordan">
                                                  Jordan
                                                </option>
                                                <option value="Kazakhstan">
                                                  Kazakhstan
                                                </option>
                                                <option value="Kenya">
                                                  Kenya
                                                </option>
                                                <option value="Kiribati">
                                                  Kiribati
                                                </option>
                                                <option value="Korea North">
                                                  Korea North
                                                </option>
                                                <option value="Korea Sout">
                                                  Korea South
                                                </option>
                                                <option value="Kuwait">
                                                  Kuwait
                                                </option>
                                                <option value="Kyrgyzstan">
                                                  Kyrgyzstan
                                                </option>
                                                <option value="Laos">
                                                  Laos
                                                </option>
                                                <option value="Latvia">
                                                  Latvia
                                                </option>
                                                <option value="Lebanon">
                                                  Lebanon
                                                </option>
                                                <option value="Lesotho">
                                                  Lesotho
                                                </option>
                                                <option value="Liberia">
                                                  Liberia
                                                </option>
                                                <option value="Libya">
                                                  Libya
                                                </option>
                                                <option value="Liechtenstein">
                                                  Liechtenstein
                                                </option>
                                                <option value="Lithuania">
                                                  Lithuania
                                                </option>
                                                <option value="Luxembourg">
                                                  Luxembourg
                                                </option>
                                                <option value="Macau">
                                                  Macau
                                                </option>
                                                <option value="Macedonia">
                                                  Macedonia
                                                </option>
                                                <option value="Madagascar">
                                                  Madagascar
                                                </option>
                                                <option value="Malaysia">
                                                  Malaysia
                                                </option>
                                                <option value="Malawi">
                                                  Malawi
                                                </option>
                                                <option value="Maldives">
                                                  Maldives
                                                </option>
                                                <option value="Mali">
                                                  Mali
                                                </option>
                                                <option value="Malta">
                                                  Malta
                                                </option>
                                                <option value="Marshall Islands">
                                                  Marshall Islands
                                                </option>
                                                <option value="Martinique">
                                                  Martinique
                                                </option>
                                                <option value="Mauritania">
                                                  Mauritania
                                                </option>
                                                <option value="Mauritius">
                                                  Mauritius
                                                </option>
                                                <option value="Mayotte">
                                                  Mayotte
                                                </option>
                                                <option value="Mexico">
                                                  Mexico
                                                </option>
                                                <option value="Midway Islands">
                                                  Midway Islands
                                                </option>
                                                <option value="Moldova">
                                                  Moldova
                                                </option>
                                                <option value="Monaco">
                                                  Monaco
                                                </option>
                                                <option value="Mongolia">
                                                  Mongolia
                                                </option>
                                                <option value="Montserrat">
                                                  Montserrat
                                                </option>
                                                <option value="Morocco">
                                                  Morocco
                                                </option>
                                                <option value="Mozambique">
                                                  Mozambique
                                                </option>
                                                <option value="Myanmar">
                                                  Myanmar
                                                </option>
                                                <option value="Nambia">
                                                  Nambia
                                                </option>
                                                <option value="Nauru">
                                                  Nauru
                                                </option>
                                                <option value="Nepal">
                                                  Nepal
                                                </option>
                                                <option value="Netherland Antilles">
                                                  Netherland Antilles
                                                </option>
                                                <option value="Netherlands">
                                                  Netherlands (Holland, Europe)
                                                </option>
                                                <option value="Nevis">
                                                  Nevis
                                                </option>
                                                <option value="New Caledonia">
                                                  New Caledonia
                                                </option>
                                                <option value="New Zealand">
                                                  New Zealand
                                                </option>
                                                <option value="Nicaragua">
                                                  Nicaragua
                                                </option>
                                                <option value="Niger">
                                                  Niger
                                                </option>
                                                <option value="Nigeria">
                                                  Nigeria
                                                </option>
                                                <option value="Niue">
                                                  Niue
                                                </option>
                                                <option value="Norfolk Island">
                                                  Norfolk Island
                                                </option>
                                                <option value="Norway">
                                                  Norway
                                                </option>
                                                <option value="Oman">
                                                  Oman
                                                </option>
                                                <option value="Pakistan">
                                                  Pakistan
                                                </option>
                                                <option value="Palau Island">
                                                  Palau Island
                                                </option>
                                                <option value="Palestine">
                                                  Palestine
                                                </option>
                                                <option value="Panama">
                                                  Panama
                                                </option>
                                                <option value="Papua New Guinea">
                                                  Papua New Guinea
                                                </option>
                                                <option value="Paraguay">
                                                  Paraguay
                                                </option>
                                                <option value="Peru">
                                                  Peru
                                                </option>
                                                <option value="Phillipines">
                                                  Philippines
                                                </option>
                                                <option value="Pitcairn Island">
                                                  Pitcairn Island
                                                </option>
                                                <option value="Poland">
                                                  Poland
                                                </option>
                                                <option value="Portugal">
                                                  Portugal
                                                </option>
                                                <option value="Puerto Rico">
                                                  Puerto Rico
                                                </option>
                                                <option value="Qatar">
                                                  Qatar
                                                </option>
                                                <option value="Republic of Montenegro">
                                                  Republic of Montenegro
                                                </option>
                                                <option value="Republic of Serbia">
                                                  Republic of Serbia
                                                </option>
                                                <option value="Reunion">
                                                  Reunion
                                                </option>
                                                <option value="Romania">
                                                  Romania
                                                </option>
                                                <option value="Russia">
                                                  Russia
                                                </option>
                                                <option value="Rwanda">
                                                  Rwanda
                                                </option>
                                                <option value="St Barthelemy">
                                                  St Barthelemy
                                                </option>
                                                <option value="St Eustatius">
                                                  St Eustatius
                                                </option>
                                                <option value="St Helena">
                                                  St Helena
                                                </option>
                                                <option value="St Kitts-Nevis">
                                                  St Kitts-Nevis
                                                </option>
                                                <option value="St Lucia">
                                                  St Lucia
                                                </option>
                                                <option value="St Maarten">
                                                  St Maarten
                                                </option>
                                                <option value="St Pierre &amp; Miquelon">
                                                  St Pierre &amp; Miquelon
                                                </option>
                                                <option value="St Vincent &amp; Grenadines">
                                                  St Vincent &amp; Grenadines
                                                </option>
                                                <option value="Saipan">
                                                  Saipan
                                                </option>
                                                <option value="Samoa">
                                                  Samoa
                                                </option>
                                                <option value="Samoa American">
                                                  Samoa American
                                                </option>
                                                <option value="San Marino">
                                                  San Marino
                                                </option>
                                                <option value="Sao Tome &amp; Principe">
                                                  Sao Tome &amp; Principe
                                                </option>
                                                <option value="Saudi Arabia">
                                                  Saudi Arabia
                                                </option>
                                                <option value="Senegal">
                                                  Senegal
                                                </option>
                                                <option value="Seychelles">
                                                  Seychelles
                                                </option>
                                                <option value="Sierra Leone">
                                                  Sierra Leone
                                                </option>
                                                <option value="Singapore">
                                                  Singapore
                                                </option>
                                                <option value="Slovakia">
                                                  Slovakia
                                                </option>
                                                <option value="Slovenia">
                                                  Slovenia
                                                </option>
                                                <option value="Solomon Islands">
                                                  Solomon Islands
                                                </option>
                                                <option value="Somalia">
                                                  Somalia
                                                </option>
                                                <option value="South Africa">
                                                  South Africa
                                                </option>
                                                <option value="Spain">
                                                  Spain
                                                </option>
                                                <option value="Sri Lanka">
                                                  Sri Lanka
                                                </option>
                                                <option value="Sudan">
                                                  Sudan
                                                </option>
                                                <option value="Suriname">
                                                  Suriname
                                                </option>
                                                <option value="Swaziland">
                                                  Swaziland
                                                </option>
                                                <option value="Sweden">
                                                  Sweden
                                                </option>
                                                <option value="Switzerland">
                                                  Switzerland
                                                </option>
                                                <option value="Syria">
                                                  Syria
                                                </option>
                                                <option value="Tahiti">
                                                  Tahiti
                                                </option>
                                                <option value="Taiwan">
                                                  Taiwan
                                                </option>
                                                <option value="Tajikistan">
                                                  Tajikistan
                                                </option>
                                                <option value="Tanzania">
                                                  Tanzania
                                                </option>
                                                <option value="Thailand">
                                                  Thailand
                                                </option>
                                                <option value="Togo">
                                                  Togo
                                                </option>
                                                <option value="Tokelau">
                                                  Tokelau
                                                </option>
                                                <option value="Tonga">
                                                  Tonga
                                                </option>
                                                <option value="Trinidad &amp; Tobago">
                                                  Trinidad &amp; Tobago
                                                </option>
                                                <option value="Tunisia">
                                                  Tunisia
                                                </option>
                                                <option value="Turkey">
                                                  Turkey
                                                </option>
                                                <option value="Turkmenistan">
                                                  Turkmenistan
                                                </option>
                                                <option value="Turks &amp; Caicos Is">
                                                  Turks &amp; Caicos Is
                                                </option>
                                                <option value="Tuvalu">
                                                  Tuvalu
                                                </option>
                                                <option value="Uganda">
                                                  Uganda
                                                </option>
                                                <option value="United Kingdom">
                                                  United Kingdom
                                                </option>
                                                <option value="Ukraine">
                                                  Ukraine
                                                </option>
                                                <option value="United Arab Erimates">
                                                  United Arab Emirates
                                                </option>
                                                <option value="United States of America">
                                                  United States of America
                                                </option>
                                                <option value="Uraguay">
                                                  Uruguay
                                                </option>
                                                <option value="Uzbekistan">
                                                  Uzbekistan
                                                </option>
                                                <option value="Vanuatu">
                                                  Vanuatu
                                                </option>
                                                <option value="Vatican City State">
                                                  Vatican City State
                                                </option>
                                                <option value="Venezuela">
                                                  Venezuela
                                                </option>
                                                <option value="Vietnam">
                                                  Vietnam
                                                </option>
                                                <option value="Virgin Islands (Brit)">
                                                  Virgin Islands (Brit)
                                                </option>
                                                <option value="Virgin Islands (USA)">
                                                  Virgin Islands (USA)
                                                </option>
                                                <option value="Wake Island">
                                                  Wake Island
                                                </option>
                                                <option value="Wallis &amp; Futana Is">
                                                  Wallis &amp; Futana Is
                                                </option>
                                                <option value="Yemen">
                                                  Yemen
                                                </option>
                                                <option value="Zaire">
                                                  Zaire
                                                </option>
                                                <option value="Zambia">
                                                  Zambia
                                                </option>
                                                <option value="Zimbabwe">
                                                  Zimbabwe
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="swift-code"
                                            >
                                              Swift Code
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="swift-code"
                                                value={swiftCode}
                                                onChange={(e) => {
                                                  setSwiftCode(e.target.value);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="description"
                                      >
                                        Description
                                      </label>
                                      <div className="form-control-wrap">
                                        <textarea
                                          className="form-control"
                                          id="description"
                                          value={description}
                                          onChange={(e) =>
                                            setDescription(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12">
                                    <button
                                      style={{ marginBottom: "200px" }}
                                      className="btn btn-primary"
                                      onClick={addTransaction}
                                    >
                                      <span>Confirm</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="toggle-overlay"
                              data-target="addProduct"
                              onClick={() => setAddTransactionModalOpen(false)}
                            ></div>
                          </>
                        )}

                        {editBalanceModalOpen && (
                          <>
                            <div
                              className="nk-add-product toggle-slide toggle-slide-right toggle-screen-any content-active"
                              data-content="addProduct"
                              data-toggle-screen="any"
                              data-toggle-overlay="true"
                              data-toggle-body="true"
                              data-simplebar
                            >
                              <div className="nk-block-head">
                                <div className="nk-block-head-content">
                                  <h5 className="nk-block-title">
                                    Edit Balance
                                  </h5>
                                </div>
                              </div>
                              <div className="nk-block">
                                <div className="row g-3">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="amount"
                                      >
                                        Amount ($)
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="amount"
                                          required
                                          value={amountToEdit}
                                          onChange={(e) =>
                                            setAmountToEdit(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12">
                                    <button
                                      className="btn btn-primary"
                                      onClick={editBalance}
                                    >
                                      <span>Confirm</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="toggle-overlay"
                              data-target="addProduct"
                              onClick={() => setEditBalanceModalOpen(false)}
                            ></div>
                          </>
                        )}

                        {createAccountModalOpen && (
                          <>
                            <div
                              className="nk-add-product toggle-slide toggle-slide-right toggle-screen-any content-active"
                              data-content="addProduct"
                              data-toggle-screen="any"
                              data-toggle-overlay="true"
                              data-toggle-body="true"
                              data-simplebar
                            >
                              <div className="nk-block">
                                <div className="row g-3">
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        First Name
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="firstName"
                                          placeholder="First Name *"
                                          required
                                          value={registerFormData.firstName}
                                          onChange={(e) => {
                                            inputChange(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Middle Name
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="middleName"
                                          placeholder="Middle Name"
                                          value={registerFormData.middleName}
                                          onChange={(e) => {
                                            inputChange(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Last Name
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="lastName"
                                          placeholder="Last Name *"
                                          required
                                          value={registerFormData.lastName}
                                          onChange={(e) => {
                                            inputChange(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Email
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="email"
                                          className="form-control"
                                          name="email"
                                          placeholder="Email address *"
                                          required
                                          value={registerFormData.email}
                                          onChange={(e) => {
                                            inputChange(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Phone
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="tel"
                                          className="form-control"
                                          name="phone"
                                          placeholder="Phone Number *"
                                          required
                                          value={registerFormData.phone}
                                          onChange={(e) => {
                                            inputChange(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Password
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="password"
                                          className="form-control"
                                          name="password"
                                          placeholder="Password *"
                                          required
                                          value={registerFormData.password}
                                          onChange={(e) => {
                                            inputChange(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Confirm Password
                                      </label>
                                      <div className="form-control-wrap">
                                        <input
                                          type="password"
                                          className="form-control"
                                          name="confirmPassword"
                                          placeholder="Confirm Password *"
                                          required
                                          value={
                                            registerFormData.confirmPassword
                                          }
                                          onChange={(e) => {
                                            inputChange(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12">
                                    <button
                                      style={{ marginBottom: "200px" }}
                                      className="btn btn-primary"
                                      onClick={createAccount}
                                    >
                                      <span>Create</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="toggle-overlay"
                              data-target="addProduct"
                              onClick={() => setCreateAccountModalOpen(false)}
                            ></div>
                          </>
                        )}
                      </div>
                      {usersToDisplay < users?.length && (
                        <div className="text-center pt-4">
                          <a
                            href="#/"
                            className="link link-soft"
                            onClick={loadMoreUsers}
                          >
                            <em className="icon ni ni-redo" />
                            <span>Load More</span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}

      {suspendModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setSuspendModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Suspend Account</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to suspend this account?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Suspending...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={suspendUser}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setSuspendModalOpen(false)}
          />
        </>
      )}

      {unsuspendModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setUnsuspendModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Unsuspend Account</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to unsuspend this user?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Unsuspending...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={unsuspendUser}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setUnsuspendModalOpen(false)}
          />
        </>
      )}

      {disableTransferModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setDisableTransferModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Disable Transfer</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to disable transfers on this
                        account?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Disabling...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={disableTransfer}
                        >
                          Disable
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setDisableTransferModalOpen(false)}
          />
        </>
      )}

      {enableTransferModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setEnableTransferModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Enable Transfer</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to enable transfers on this
                        account?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Enabling...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={enableTransfer}
                        >
                          Enable
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setEnableTransferModalOpen(false)}
          />
        </>
      )}

      {verifyModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setVerifyModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Verify Email</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to verify this user?
                      </p>
                    </div>
                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Verifying...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={verifyEmail}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setVerifyModalOpen(false)}
          />
        </>
      )}

      <ToastContainer
        position="bottom-center"
        theme="dark"
        autoClose={3000}
        closeButton={false}
      />
    </>
  );
};

export default Users;
