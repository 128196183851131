import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import Preloader from "../components/Preloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserDetails = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<any>({});
  const [tabSelected, setTabSelected] = useState("personal");
  const [idToApprove, setIdToApprove] = useState("");
  const [idToReject, setIdToReject] = useState("");
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [userTransactions, setUserTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const selectPersonal = () => {
    setTabSelected("personal");
  };

  const selectTransactions = () => {
    setTabSelected("transactions");
  };

  const approve = async () => {
    const token = localStorage.getItem("firstwebster-admin");

    setLoading(true);

    try {
      await axios.put(
        "https://api.firstwebster.com/api/transaction/approve",
        { idToApprove },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction approved!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const reject = async () => {
    const token = localStorage.getItem("firstwebster-admin");

    setLoading(true);

    try {
      await axios.put(
        "https://api.firstwebster.com/api/transaction/reject",
        { idToReject },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      toast.success("Transaction rejected!");
      setTimeout(() => {
        navigate(0);
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("firstwebster-admin");

    const getUserDetails = async () => {
      const { data } = await axios.get(
        `https://api.firstwebster.com/api/user/${id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setUserDetails(data);
    };

    getUserDetails();
  }, [id]);

  useEffect(() => {
    const token = localStorage.getItem("firstwebster-admin");

    const getUserTransactions = async () => {
      const { data } = await axios.get(
        `https://api.firstwebster.com/api/transaction/${id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setUserTransactions(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getUserTransactions();
  }, [id]);

  return (
    <>
      {pageLoading ? (
        <Preloader />
      ) : (
        <div className="nk-body bg-lighter npc-general has-sidebar ui-bordered ">
          <div className="nk-app-root">
            <div className="nk-main ">
              <Sidebar />
              <div className="nk-wrap ">
                <Header />
                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                          <div className="nk-block-between g-3">
                            <div className="nk-block-head-content">
                              <h3 className="nk-block-title page-title">
                                Users /{" "}
                                <strong className="text-primary small">
                                  {userDetails?.name}
                                </strong>
                              </h3>
                            </div>
                            <div className="nk-block-head-content">
                              <Link
                                to="/users"
                                className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                              >
                                <em className="icon ni ni-arrow-left" />
                                <span>Back</span>
                              </Link>
                              <Link
                                to="/users"
                                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                              >
                                <em className="icon ni ni-arrow-left" />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="card">
                            <div className="card-aside-wrap">
                              <div className="card-content">
                                <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                                  <li className="nav-item">
                                    <a
                                      className={`nav-link ${
                                        tabSelected === "personal"
                                          ? "active"
                                          : ""
                                      }`}
                                      href="#/"
                                      onClick={selectPersonal}
                                    >
                                      <em className="icon ni ni-user-circle" />
                                      <span>Personal</span>
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      className={`nav-link ${
                                        tabSelected === "transactions"
                                          ? "active"
                                          : ""
                                      }`}
                                      href="#/"
                                      onClick={selectTransactions}
                                    >
                                      <em className="icon ni ni-repeat" />
                                      <span>Transactions</span>
                                    </a>
                                  </li>
                                </ul>
                                {tabSelected === "personal" ? (
                                  <div className="card-inner">
                                    <div className="nk-block">
                                      <div className="nk-block-head">
                                        <h5 className="title">
                                          Personal Information
                                        </h5>
                                      </div>
                                      <div className="profile-ud-list">
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Full Name
                                            </span>
                                            <span className="profile-ud-value">
                                              {userDetails?.firstName}{" "}
                                              {userDetails?.middleName}{" "}
                                              {userDetails?.lastName}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Mobile Number
                                            </span>
                                            <span className="profile-ud-value">
                                              {userDetails?.phone}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Account Number
                                            </span>
                                            <span className="profile-ud-value">
                                              {userDetails?.accountNumber}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Email Address
                                            </span>
                                            <span className="profile-ud-value">
                                              {userDetails?.email}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Occupation
                                            </span>
                                            <span className="profile-ud-value">
                                              {userDetails?.occupation}
                                            </span>
                                          </div>
                                        </div>

                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Address
                                            </span>
                                            <span className="profile-ud-value">
                                              {userDetails?.address}
                                            </span>
                                          </div>
                                        </div>
                                        {userDetails?.picture && (
                                          <div className="profile-ud-item">
                                            <div className="profile-ud wider">
                                              <span className="profile-ud-label">
                                                Picture
                                              </span>
                                              <span className="profile-ud-value">
                                                <a
                                                  href={userDetails?.picture}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  View
                                                </a>
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Balance
                                            </span>
                                            <span className="profile-ud-value">
                                              $
                                              {userDetails?.balance?.toLocaleString()}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="nk-block">
                                      <div className="nk-block-head nk-block-head-line">
                                        <h6 className="title overline-title text-base">
                                          Additional Information
                                        </h6>
                                      </div>
                                      <div className="profile-ud-list">
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Joining Date
                                            </span>
                                            <span className="profile-ud-value">
                                              {new Date(
                                                userDetails?.createdAt
                                              ).toLocaleString()}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Reg Method
                                            </span>
                                            <span className="profile-ud-value">
                                              Email
                                            </span>
                                          </div>
                                        </div>
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Status
                                            </span>
                                            {userDetails?.disabled ? (
                                              <span className="profile-ud-value">
                                                Suspended
                                              </span>
                                            ) : (
                                              <span className="profile-ud-value">
                                                Active
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="profile-ud-item">
                                          <div className="profile-ud wider">
                                            <span className="profile-ud-label">
                                              Verified
                                            </span>
                                            {userDetails?.verified ? (
                                              <span className="profile-ud-value">
                                                Yes
                                              </span>
                                            ) : (
                                              <span className="profile-ud-value">
                                                No
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="nk-divider divider md" />
                                  </div>
                                ) : (
                                  <div className="card-inner">
                                    <div className="nk-block">
                                      <div className="nk-tb-list is-separate is-medium mb-3">
                                        <div className="nk-tb-item nk-tb-head">
                                          <div className="nk-tb-col">
                                            <span>ID</span>
                                          </div>
                                          <div className="nk-tb-col tb-col-md">
                                            <span>Date</span>
                                          </div>
                                          <div className="nk-tb-col">
                                            <span className="d-none d-sm-block">
                                              Status
                                            </span>
                                          </div>
                                          <div className="nk-tb-col tb-col-md">
                                            <span>Type</span>
                                          </div>
                                          <div className="nk-tb-col">
                                            <span>Amount</span>
                                          </div>
                                          <div className="nk-tb-col nk-tb-col-tools"></div>
                                        </div>
                                        {userTransactions?.map(
                                          (transaction: any) => (
                                            <div
                                              className="nk-tb-item"
                                              key={transaction._id}
                                            >
                                              <div className="nk-tb-col">
                                                <span className="tb-lead">
                                                  <a href="#/">
                                                    #{transaction.transactionId}
                                                  </a>
                                                </span>
                                              </div>
                                              <div className="nk-tb-col tb-col-md">
                                                <span className="tb-sub">
                                                  {new Date(
                                                    transaction.dateTime
                                                  ).toDateString()}
                                                </span>
                                              </div>
                                              {transaction.status ===
                                                "Pending" && (
                                                <div className="nk-tb-col">
                                                  <span className="dot bg-warning d-sm-none" />
                                                  <span className="badge badge-sm badge-dot has-bg bg-warning d-none d-sm-inline-flex">
                                                    Pending
                                                  </span>
                                                </div>
                                              )}
                                              {transaction.status ===
                                                "Unconfirmed" && (
                                                <div className="nk-tb-col">
                                                  <span className="dot bg-warning d-sm-none" />
                                                  <span className="badge badge-sm badge-dot has-bg bg-warning d-none d-sm-inline-flex">
                                                    Unconfirmed
                                                  </span>
                                                </div>
                                              )}
                                              {transaction.status ===
                                                "Completed" && (
                                                <div className="nk-tb-col">
                                                  <span className="dot bg-success d-sm-none" />
                                                  <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">
                                                    Completed
                                                  </span>
                                                </div>
                                              )}
                                              {transaction.status ===
                                                "Cancelled" && (
                                                <div className="nk-tb-col">
                                                  <span className="dot bg-danger d-sm-none" />
                                                  <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">
                                                    Cancelled
                                                  </span>
                                                </div>
                                              )}
                                              <div className="nk-tb-col tb-col-md">
                                                {transaction.type}
                                              </div>
                                              <div className="nk-tb-col">
                                                <span className="tb-lead">
                                                  $
                                                  {transaction.amount?.toLocaleString()}
                                                </span>
                                              </div>
                                              <div className="nk-tb-col nk-tb-col-tools">
                                                <ul className="nk-tb-actions gx-1">
                                                  <li>
                                                    <div className="drodown me-n1">
                                                      <a
                                                        href="#/"
                                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                                        data-bs-toggle="dropdown"
                                                      >
                                                        <em className="icon ni ni-more-h" />
                                                      </a>
                                                      <div className="dropdown-menu dropdown-menu-end">
                                                        <ul className="link-list-opt no-bdr">
                                                          <li>
                                                            <Link
                                                              to={`/transaction/${transaction.transactionId}`}
                                                            >
                                                              <em className="icon ni ni-eye" />
                                                              <span>
                                                                Transaction
                                                                Details
                                                              </span>
                                                            </Link>
                                                          </li>
                                                          {transaction.status ===
                                                            "Pending" && (
                                                            <li>
                                                              <a
                                                                href="#/"
                                                                onClick={() => {
                                                                  setIdToApprove(
                                                                    transaction._id
                                                                  );
                                                                  setApproveModalOpen(
                                                                    true
                                                                  );
                                                                }}
                                                              >
                                                                <em className="icon ni ni-money" />
                                                                <span>
                                                                  Approve
                                                                </span>
                                                              </a>
                                                            </li>
                                                          )}
                                                          {transaction.status ===
                                                            "Pending" && (
                                                            <li>
                                                              <a
                                                                href="#/"
                                                                onClick={() => {
                                                                  setIdToReject(
                                                                    transaction._id
                                                                  );
                                                                  setRejectModalOpen(
                                                                    true
                                                                  );
                                                                }}
                                                              >
                                                                <em className="icon ni ni-trash" />
                                                                <span>
                                                                  Reject
                                                                </span>
                                                              </a>
                                                            </li>
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      {/* <div className="card">
                        <div className="card-inner">
                          <div className="nk-block-between-md g-3">
                            <div className="g">
                              <ul className="pagination justify-content-center justify-content-md-start">
                                <li className="page-item">
                                  <a className="page-link" href="#/">
                                    <em className="icon ni ni-chevrons-left" />
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a className="page-link" href="#/">
                                    1
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a className="page-link" href="#/">
                                    2
                                  </a>
                                </li>
                                <li className="page-item">
                                  <span className="page-link">
                                    <em className="icon ni ni-more-h" />
                                  </span>
                                </li>
                                <li className="page-item">
                                  <a className="page-link" href="#/">
                                    6
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a className="page-link" href="#/">
                                    7
                                  </a>
                                </li>
                                <li className="page-item">
                                  <a className="page-link" href="#/">
                                    <em className="icon ni ni-chevrons-right" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="g">
                              <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                <div>Page</div>
                                <div>
                                  <select
                                    className="form-select js-select2"
                                    data-search="on"
                                    data-dropdown="xs center"
                                  >
                                    <option value="page-1">1</option>
                                    <option value="page-2">2</option>
                                    <option value="page-4">4</option>
                                    <option value="page-5">5</option>
                                    <option value="page-6">6</option>
                                    <option value="page-7">7</option>
                                    <option value="page-8">8</option>
                                    <option value="page-9">9</option>
                                    <option value="page-10">10</option>
                                    <option value="page-11">11</option>
                                    <option value="page-12">12</option>
                                    <option value="page-13">13</option>
                                    <option value="page-14">14</option>
                                    <option value="page-15">15</option>
                                    <option value="page-16">16</option>
                                    <option value="page-17">17</option>
                                    <option value="page-18">18</option>
                                    <option value="page-19">19</option>
                                    <option value="page-20">20</option>
                                  </select>
                                </div>
                                <div>OF 102</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="bottom-center"
        theme="dark"
        autoClose={3000}
        closeButton={false}
      />

      {approveModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setApproveModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Approve</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to approve this transaction?
                      </p>
                    </div>

                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Approving...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={approve}
                        >
                          Approve
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setApproveModalOpen(false)}
          />
        </>
      )}

      {rejectModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalAlert2"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setRejectModalOpen(false)}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Reject</h4>
                    <div className="nk-modal-text">
                      <p className="lead">
                        Are you sure you want to reject this transaction?
                      </p>
                    </div>

                    <div className="nk-modal-action mt-5">
                      {loading ? (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                        >
                          Proceeding...
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-lg btn-mw btn-light"
                          data-bs-dismiss="modal"
                          onClick={reject}
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setRejectModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default UserDetails;
