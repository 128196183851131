const Preloader = () => {
  return (
    <div
      className="preloader"
      id="preloader"
      style={{ display: "block", opacity: "1" }}
    />
  );
};

export default Preloader;
